import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
const sliderParentClass = '.js-blog-slider';
const sliderClass = '.js-blog-slider-slider';
const sliderPrevClass = '.js-blog-slider-prev';
const sliderNextClass = '.js-blog-slider-next';

const contentSlider = sliderElm => {
    const sliderEl = sliderElm.querySelector(sliderClass);
    const sliderPrev = sliderElm.querySelectorAll(sliderPrevClass);
    const sliderNext = sliderElm.querySelectorAll(sliderNextClass);
    const sliderIdentifier = sliderEl.id;
    const swiper = new Swiper(`#${sliderIdentifier}`, {
        modules: [Navigation],
        slidesPerView: 1,
        loop: false,
        spaceBetween: 32,
        navigation: {
            prevEl: sliderPrevClass,
            nextEl: sliderNextClass,
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
            1220: {
                slidesPerView: 2,
                spaceBetween: 32,
            },
            1680: {
                slidesPerView: 2,
                spaceBetween: 40,

            },
        },
    });

    swiper.on('slideChangeTransitionEnd', function (obj) {
        if (obj.isBeginning) {
            sliderPrev.forEach(item => {
                item.classList.add('is-end');
            });
        } else {
            sliderPrev.forEach(item => {
                item.classList.remove('is-end');
            });
        }
        if (obj.isEnd) {
            sliderNext.forEach(item => {
                item.classList.add('is-end');
            });
        } else {
            sliderNext.forEach(item => {
                item.classList.remove('is-end');
            });
        }
    });
    resize(swiper);

    window.addEventListener(
        'resize',
        function (event) {
            resize(swiper);
        },
        true
    );

    function resize(slider) {
        if (slider) {
            const { slides } = slider;

            if (window.innerWidth > 1220) {
                if (slides.length <= 3) {
                    sliderPrev.forEach(item => {
                        item.classList.add('is-hidden');
                    });
                    sliderNext.forEach(item => {
                        item.classList.add('is-hidden');
                    });
                } else {
                    sliderPrev.forEach(item => {
                        item.classList.remove('is-hidden');
                    });
                    sliderNext.forEach(item => {
                        item.classList.remove('is-hidden');
                    });
                }
            } else if (window.innerWidth > 768) {
                if (slides.length <= 2) {
                    sliderPrev.forEach(item => {
                        item.classList.add('is-hidden');
                    });
                    sliderNext.forEach(item => {
                        item.classList.add('is-hidden');
                    });
                } else {
                    sliderPrev.forEach(item => {
                        item.classList.remove('is-hidden');
                    });
                    sliderNext.forEach(item => {
                        item.classList.remove('is-hidden');
                    });
                }
            } else {
                if (slides.length <= 1) {
                    sliderPrev.forEach(item => {
                        item.classList.add('is-hidden');
                    });
                    sliderNext.forEach(item => {
                        item.classList.add('is-hidden');
                    });
                } else {
                    sliderPrev.forEach(item => {
                        item.classList.remove('is-hidden');
                    });
                    sliderNext.forEach(item => {
                        item.classList.remove('is-hidden');
                    });
                }
            }
        }
    }

    return {
        swiper,
    };
};

const allSliders = document.querySelectorAll(sliderParentClass);
if (allSliders) {
    allSliders.forEach(slider => contentSlider(slider));
}
